import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import {  SurveyGrid, SurveySearchFilter } from '@survey/components'
import { mapActions, mapGetters } from 'vuex';
import * as types from '@survey/store/types'
import * as accountTypes from '@account/store/types'
import * as menuHelper from '@appbase/util/menu-helper'

@Component({
	components: {
		SurveySearchFilter,
		SurveyGrid
	},
	methods: {
		...mapActions({
			getList: types.SURVEY_LIST,
			setSurveyId: types.SURVEY_CREATED_SURVEYID
		})
	},
	computed: {
        ...mapGetters({
            user : accountTypes.CURRENT,
			menuPermission : types.SURVEY_MENU_PERMISSIONS,
            listTotalCount : types.SURVEY_LIST_TOTALCOUNT
        })
    }
})

export default class SurveyListView extends Vue {
	getList!: (params: survey.SurveyListRequest) => void
	setSurveyId!: (surveyId?: number) => void

	user! : account.TCurrent
	menuPermission! : survey.MenuPermissions
	listTotalCount! : number

	gridRowCount : number = 10
	gridPageRange : number = 5
 

	searchedKeyword : string = ""
	searchedFilter : number = 0
	searchedSurveyStatus : number = 0

	isComposing : boolean = false
	clickSearchBtn : boolean = false


    @Watch("$route.query.MENUID")
    onChangedMenuId(){
		//검색어 필터 상태 초기화
		this.searchedKeyword  = ""
		this.searchedFilter  = 0
		this.searchedSurveyStatus = 0
		this.clickSearchBtn = false

        this.init()
		this.setMenuTitle()

    }
 

	created() {
		this.setMenuTitle()
		this.init()
	}

	init(pageNum? : number){

		//초기화 시 
		var isComposing = parseInt(this.$route.query.isComposing as string)
 
		this.search({
			filter : this.searchedFilter,
			surveyStatus : this.searchedSurveyStatus,
			keyword : this.searchedKeyword,
			rowCount : this.gridRowCount,
			pageNumber : pageNum || 1,
			isComposing : isNaN(isComposing) ? false : true
		})

		if(isNaN(isComposing)){
			this.isComposing = false
		}else{
			this.isComposing = isComposing == 0 ? false : true
		}
		 
		
	}

	setMenuTitle(){
		let menuTitle = ""
		const menuConfig = menuHelper.default.findOne(parseInt(this.$route.query.MENUID as string))
		const userConfig = this.user.userConfig
	 

		if(userConfig.language === "ko-KR"){
			menuTitle = <string>menuConfig?.menuName 
		}else{
			menuTitle = <string>menuConfig?.menuNameEn 
		}
	 
 

		this.setPage({
			title: this.$t("설문조사").toString()
		})
	}


	search(params: survey.SurveyListRequest) {
		//기본 설정 값 rowsize
		params.rowCount = this.gridRowCount
		
		//검색어 저장, 페이징에서 검색 처리 시 이전 검색어 사용
		

		//검색필터에서 오는 경우 pagenumber 없음
		if(params.pageNumber == null){
			params.pageNumber = 1
		}
		
		//페이징에서 오는 경우 검색 조건(필터,상태,키워드) 없음
		//null 아니면 기록
		if(params.filter == null){
			params.filter = this.searchedFilter
		}else{
			this.searchedFilter = params.filter
		}
		
		if(params.surveyStatus == null){
			params.surveyStatus = this.searchedSurveyStatus
		}else{
			this.searchedSurveyStatus =  params.surveyStatus
		}
		
		if(params.keyword == null){
			params.keyword = this.searchedKeyword
		}{
			this.searchedKeyword = params.keyword
		}

		var isComposing = parseInt(this.$route.query.isComposing as string)
		params.isComposing = isNaN(isComposing) ? false : true

		//isComposing 이 0일 떄 는 false 로 변경
		if(isComposing == 0){
			params.isComposing = false
		}

		//메뉴아이디 추가
		params.menuId = parseInt(<string>this.$route.query.MENUID)
		
		this.getList(params)
		
	}

	goWrite(){
		//진입전에 surveyId 초기화
		this.setSurveyId()
		
		this.$router.push({
            name : "SurveyWriteStep01",
			query: { 
                ...this.$route.query
            }
        })
	}

	@Watch("$route.query.isComposing")
	onChangedQuery(){
		this.init()
	}


	pageMove(pageNum: number){
		
		this.init(pageNum)
	}

	switchListPage(isComposing :boolean){
		

		this.$router.push({
            name : "SurveyList",
			query: { 
                ...this.$route.query,
				isComposing : isComposing ? "1" : "0"
            }
        })
	}

	onClickSearchBtn(params:survey.SurveyListRequest){
		this.search(params)
		this.clickSearchBtn = true
	}

	
}
